import React, { useEffect, useState } from "react";
import "../subcomponents/IfProjectCreated.css";
// import { FaBackward } from "react-icons/fa";
import { FaBackwardFast, FaForwardFast } from "react-icons/fa6";

import // allowCreateProject,
// changeUserAccess,
// handleIsActive,
"../../Store/UserConditonsSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router";
import { addProject } from "../../Store/AllProjectsSlice";
import _ from "lodash";
// import { useLocation } from "react-router-dom";
import { EDIT_PROJECT } from "../../util/ApiEndPoints";
// import { getUsers } from "../../Store/UserSlice";
const IfProjectCreated = ({
  activeApp,
  //   setIsProjectCreated,
  setShowSelect,
  setIsProjectedit,
  activeProject,
}) => {
  // const { state } = useLocation();
  const { usersList } = useSelector((state) => state?.usersList);
  // console.log("activeProject", activeProject);
  // console.log("activeApp||", activeApp);
  const savedUserDetails = localStorage.getItem("userDetails");
  const { orgId } = JSON.parse(savedUserDetails);
  const userDetails = JSON.parse(savedUserDetails);
  const dispatch = useDispatch();
  const [project_name, setProject_name] = useState(
    activeProject?.projectName || ""
  );
  const [rlhf_objective, setrlhf_objective] = useState(
    activeProject?.rlhfObjective || ""
  );
  const [userList, setUserList] = useState([]);

  // useEffect(() => {
  //   dispatch(
  //     getUsers({
  //       appId: activeApp?.app_id,
  //     })
  //   );
  // }, [activeApp?.app_id, dispatch]);

  const handledispatch = () => {
    setIsProjectedit(false);
    // dispatch(changeUserAccess(false));
    // dispatch(handleIsActive(false));
    // dispatch(allowCreateProject(false));
  };

  // console.log("usersList>>>>usersList", usersList);
  // console.log("state>>>>state", state);
  useEffect(() => {
    setUserList(usersList?.users);
  }, [usersList]);
  const onSelectValue = (id, key, val) => {
    // console.log("id ,key,val", id, key, val, userList);
    const newUserList = userList?.map((item, index) => {
      if (item.emailId === id) {
        return {
          ...item,
          [key]: val,
        };
      } else {
        return item;
      }
    });
    setUserList(newUserList);
  };

  const handleAddProjects = () => {
    if (!project_name || !rlhf_objective) {
      alert("Fill all details");
      return;
    }
    let url = EDIT_PROJECT;
    const keepOnlySpecifiedKeys = () => {
      const allowedKeys = ["emailId", "role", "weightage"];
      return _.map(userList, (obj) => _.pick(obj, allowedKeys));
    };
    // console.log("keepOnlySpecifiedKeys", keepOnlySpecifiedKeys);
    let payload = {
      projectId: activeProject?.projectId,
      projectName: project_name,
      rlhfObjective: rlhf_objective,
      users: keepOnlySpecifiedKeys(),
      orgId: userDetails?.orgId,
    };
    try {
      dispatch(addProject({ payload, url, dispatch, activeApp, orgId }));
      // dispatch(getAllProjects({ orgId: orgId, appId: activeApp?.app_id }));
      setIsProjectedit(false);
      setShowSelect(false);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };
  return (
    <>
      <div className="projectselectedtop">
        <div className="previousandnextcontent">
          <div className="backdirection" onClick={handledispatch}>
            <FaBackwardFast />
            Previous
          </div>
          {project_name && rlhf_objective && (
            <div className="backdirection" onClick={() => handleAddProjects()}>
              Save
              <FaForwardFast />
            </div>
          )}
        </div>
        <div className="projectandrlhfinputs">
          <input
            onChange={(e) => setProject_name(e.target.value)}
            value={project_name}
            type="text"
            placeholder="Project Name *"
            required
          />
          <input
            onChange={(e) => setrlhf_objective(e.target.value)}
            type="text"
            value={rlhf_objective}
            placeholder="RLHF Objective *"
            required
          />
        </div>
      </div>
      <div className="projectselectedmainbody">
        <div className="updateusernameandrole">
          <h2>Update User Roles and Weightage</h2>
          <h3>
            Default Roles and Weightage for the below users are defined
            according to overall App Configuration.
          </h3>
        </div>
        <div className="usernameandroletable">
          <div className="userdetailsheadings">
            <div>Email ID</div>
            <div>First Name</div>
            <div>Last Name</div>
            <div>Role</div>
            <div>Weightage</div>
          </div>
          <div className="scrollableuserdetails">
            {userList?.map((user, index) => (
              <div key={index} className="userdetailsheadingswithborder">
                <div>{user.emailId}</div>
                <div>{user.userFirstName}</div>
                <div>{user.userLastName}</div>
                <div className="rolewithdropdown">
                  <select
                    value={user?.role}
                    onChange={(e) =>
                      onSelectValue(user.emailId, "role", e.target.value)
                    }
                    className="selecteditem"
                  >
                    <option>Null</option>
                    <option>Read</option>
                    <option>Write</option>
                    <option>Admin</option>
                  </select>
                </div>
                <div>
                  <select
                    value={user?.weightage}
                    onChange={(e) =>
                      onSelectValue(user.emailId, "weightage", e.target.value)
                    }
                    className="selecteditem"
                  >
                    <option>Beginner</option>
                    <option>Intermediatery</option>
                    <option>Advanced</option>
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default IfProjectCreated;
