import React from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  changeUserAccess,
  allowCreateProject,
} from "../Store/UserConditonsSlice";
import yojn from "../Assets/yojn.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // console.log("savedUserDetails::", savedUserDetails);

  const handleLogout = () => {
    const shouldLogout = window.confirm("Are you sure you want to logout?");

    if (shouldLogout) {
      localStorage.removeItem("userDetails");
      navigate("/");
    }
  };

  // const handletraces = () => {
  //   dispatch(allowCreateProject(false));
  // };
  const handleProject = () => {
    dispatch(changeUserAccess(false));
    dispatch(allowCreateProject(false));
    navigate("/adminanduserprojectslist");
  };

  // const downloadLogs = async () => {
  //   try {
  //     const res = await axios.get("https://devapi.yojn.dev/rlhf/api/getlog");
  //     const url = window.URL.createObjectURL(new Blob([res.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "recipt.excel");
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.log("Error in downloadin Logs", error);
  //   }
  // };

  return (
    <div className="sidebar">
      <div className="upperSidebar">
        <div className="brand">
          <img src={yojn} alt="yojnlogo" />
        </div>
        <div className="properties">
          <Link to={"/user/home"} className="list-items">
            <button
              className={`listitembutton ${
                location.pathname === "/user/home" && "activesidebartab"
              }`}
            >
              Traces
            </button>
          </Link>
          {/* <Link
            to={"/user/adminanduserprojectslist"}
            className="list-items"
            onClick={() => handleProject()}
          >
            <button
              className={`listitembutton ${
                location.pathname === "/user/adminanduserprojectslist" &&
                "activesidebartab"
              }`}
            >
              Projects
            </button>
          </Link> */}
          <Link to="/user/logs" className="list-items">
            <button
              // onClick={() => navigate("/user/logs")}
              className={`listitembutton ${
                location.pathname === "/user/logs" && "activesidebartab"
              }`}
            >
              Download Logs
            </button>
          </Link>
          <Link
            to="/user/finetune"
            className="list-items"
            style={{ display: `${userDetails?.isDisable ? "none" : ""}` }}
          >
            <button
              className={`listitembutton ${
                location.pathname === "/user/finetune" && "activesidebartab"
              }`}
            >
              Finetune
            </button>
          </Link>
          <Link
            to="/user/pmdashboard"
            className="list-items"
            style={{ display: `${userDetails?.isDisable ? "none" : ""}` }}
          >
            <button
              className={`listitembutton ${
                location.pathname === "/user/pmdashboard" && "activesidebartab"
              }`}
            >
              PMDashboard
            </button>
          </Link>
        </div>
      </div>

      <div className="lowerSidebar">
        <div className="properties">
          <div className="list-items" style={{}}>
            <button
              disabled={userDetails?.isDisable}
              style={{
                color: userDetails?.isDisable && "#cdcdcd",
                display: `${userDetails?.isDisable ? "none" : ""}`,
              }}
              className={`listitembutton ${
                location.pathname === "/user/maindashboardusers" &&
                "activesidebartab"
              }`}
              onClick={() => {
                dispatch(changeUserAccess(true));
                dispatch(allowCreateProject(false));
                navigate("/user/maindashboardusers");
              }}
            >
              User Access
            </button>
          </div>
          <div className="list-items">
            <button
              className="listitembutton"
              onClick={() => navigate("/user/userprofile")}
            >
              Profile
            </button>
          </div>
          <div className="list-items">
            <button className="listitembutton" onClick={handleLogout}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
