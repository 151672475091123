import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import CardDetails from "./pages/CardDetails";
import UserDetails from "./pages/UserDetails";
import MainDashboard from "./pages/MainDashboard";
import LoginUser from "./pages/LoginUser";
import DownloadLogs from "./pages/DownloadLogs";
import UserProfile from "./pages/UserProfile";
import AuthGuard from "./util/authguard";
import NotFound from "./pages/NotFound";
import EditUserprofile from "./pages/EditUserprofile";
import MainDashboardMainUserPage from "./components/subcomponents/MainDashboardUsersPage";
import FinetunePage from "./pages/fintunePage/Finetune";
import PMDashboard from "./pages/pmdashboard/PMDashboard";
import ViewProjectDetail from "./components/pmdashboardcomponents/ViewProjectDetail";
// import { Redirect } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginUser />} />
        <Route path="user" element={<AuthGuard />}>
          <Route path="" element={<Navigate to="home" />} />
          <Route path="adminanduserprojectslist" element={<MainDashboard />} />
          <Route path="userprofile" element={<UserProfile />} />
          <Route path="editprofile" element={<EditUserprofile />} />
          <Route path="logs" element={<DownloadLogs />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="home" element={<Home />} />
          <Route path="card" element={<CardDetails />} />
          <Route path="userdetails" element={<UserDetails />} />
          <Route
            path="maindashboardusers"
            element={<MainDashboardMainUserPage />}
          />
          <Route path="finetune" element={<FinetunePage />} />
          <Route path="pmdashboard" element={<PMDashboard />} />
          <Route path="viewprojectdetails" element={<ViewProjectDetail />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
